
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import image1 from "assets/images/";
import MDAlert from "components/MDAlert";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import chatgptimage from "assets/images/chatgpt.png"
// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@mui/icons-material/Check';
import GridContainer from "components/Grid/GridContainer.js";
// import AskChatGPTBlot from "./Quillblot.js";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MDSnackbar from "components/MDSnackbar";
import YouTubeIcon from '@mui/icons-material/YouTube';
import Divider from "@mui/material/Divider";
import { ClientJS } from 'clientjs';
import DefaultItem from "examples/Items/DefaultItem";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Firebase from "firebase";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// Material Dashboard 2 PRO React examples
const firebaseConfig = {
  apiKey: "AIzaSyDSaYuHlpFznfk_nhTcitcoGG-8QUGK1ec",
  authDomain: "onroad3-e593b.firebaseapp.com",
  databaseURL: "https://onroad3-e593b-default-rtdb.firebaseio.com",
  projectId: "onroad3-e593b",
  storageBucket: "onroadai",
  messagingSenderId: "587064425563",
}
Firebase.initializeApp(firebaseConfig);
const removeEmpty = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};



function Home() {
  const quillRef = useRef();

  const [currentUserUUID, setCurrentUserUUID] = useState();
  const [singnedIn, setSignedIn] = useState(false);
  const [isTeslaCarBrowser, setIsTeslaCarBrowser] = useState(false);
  const [currentUserSearches, setCurrentUserSearches] = useState();
  const [mostPopularSearches, setMostPopularSearches] = useState();
  const [askingChatGPT, setAskingChatGPT] = useState()
  const [recentSearch, setRecentSearch] = useState()
  const [documentOpen, setDocumentOpen] = useState()
  const [previousPrompt, setPreviousPrompt] = useState()
  const [editorHtml, setEditorHtml] = useState('');
  const [editorLastLine, setEditorLastLine] = useState('');
  const [userEnteredPosition, setUserEnteredPosition] = useState(0);
  const [emailModal, setEmailModal] = useState()
  const [contentEmailToSend, setContentEmailToSend] = useState()
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [watchVideo, setWatchVideo] = useState(false)
  const [myContent, setMyContent] = useState([]);
  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const [formValue, setFormValue] = useState();
  const handeSetFormValue = ({ currentTarget }) => setFormValue(currentTarget.value);

  const client = new ClientJS();
  useEffect(() => {
    const fingerprint = client.getFingerprint();
    const btdata = client.getBrowserData();
    if (btdata && btdata.device && btdata.device.vendor === 'Tesla') {
      setIsTeslaCarBrowser(true)
    }
  }, []);

  useEffect(() => {
      if(singnedIn){
        return;
      }
      Firebase.auth().signInAnonymously()
      .then(() => {
        setSignedIn(true)
      })
      .catch((error) => {
        setSignedIn(false)
        var errorCode = error.code;
        var errorMessage = error.message;
      });
  }, [isTeslaCarBrowser]);

  useEffect(async () => {
    if(!singnedIn || !isTeslaCarBrowser){
      return;
    }
    const userUUid = await localStorage.getItem('onroad-uuid');
    if (userUUid) {
      setCurrentUserUUID(userUUid)
      const onChildAdd = Firebase.database()
      .ref(`/onroadai/users/${userUUid}/searches`)
      .limitToLast(10)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];
            if (element && element.prompt && element.responsefield && element.time) {
              result.push({prompt: element.prompt, iframeUrl: element.responsefield, time: element.time})
            }
          }
          setCurrentUserSearches(result)
        }
      });
     return () => Firebase.database().ref(`/onroadai/users/${userUUid}/searches`).off('value', onChildAdd);
    } else {
      const ref = await Firebase.database().ref(`/onroadai/users/`).push({ "browserfingerprint": client.getFingerprint() })
      let result = []
      result["browserdata"] = client.getBrowserData();
      result["language"] = {"language" : client.getLanguage(), "system-language": client.getSystemLanguage()}
      result["canvasprint"] = client.getCanvasPrint()
      result["screen"]= client.getScreenPrint()
      result["font"] = client.getFonts()
      result["ismobile"] = client.isMobile()
      result["fingerprintid"] = client.getFingerprint()
      Firebase.database().ref(`/onroadai/users/${ref.key}`).update({
        "profile": { "id": ref.key, "email": "" },
        "browserfingerprint": removeEmpty(result),
      })
      localStorage.setItem('onroad-uuid', ref.key)
    }
    
  }, [singnedIn, isTeslaCarBrowser]);


  useEffect(async () => {
    if(!currentUserUUID){
      return;
    }
    const onChildAdd = Firebase.database()
      .ref(`/onroadai/users/${currentUserUUID}/content`)
      .limitToLast(10)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];
              result.push(element)
          }
          setMyContent(result)
        }
      });
     return () => Firebase.database().ref(`/onroadai/users/${currentUserUUID}/content`).off('value', onChildAdd);
    
  }, [currentUserUUID]);


  // useEffect(() => {

  //   const getQuillBlots = async () => {
  //     const ReactQuill = (await import('react-quill')).default;
  //     const Inline = ReactQuill.Quill.import('blots/inline');
  //     class ButtonBlot extends Inline {
  //       static create(value) {
  //         const node = super.create(value);
  //         node.setAttribute('style', 'padding:8px 20px;border-radius:12px;border: 1px solid #DDDDDD;');
  //         return node;
  //       }
  //     }
  //     ButtonBlot.blotName = 'button';
  //     ButtonBlot.tagName = 'button';
  //     ReactQuill.Quill.register('formats/button', ButtonBlot);
  //     const AlignStyle = ReactQuill.Quill.import('attributors/style/align');
  //     const BackgroundStyle = ReactQuill.Quill.import('attributors/style/background');
  //     const ColorStyle = ReactQuill.Quill.import('attributors/style/color');
  //     const DirectionStyle = ReactQuill.Quill.import('attributors/style/direction');
  //     const SizeStyle = ReactQuill.Quill.import('attributors/style/size');
  //     ReactQuill.Quill.register(AlignStyle, true);
  //     ReactQuill.Quill.register(BackgroundStyle, true);
  //     ReactQuill.Quill.register(ColorStyle, true);
  //     ReactQuill.Quill.register(DirectionStyle, true);
  //     ReactQuill.Quill.register(SizeStyle, true);

  //     const BlockPrototype = ReactQuill.Quill.import('blots/block');
  //     class CustomBlock extends BlockPrototype {
  //       constructor(domNode, value) {
  //         super(domNode, value);
  //         /* eslint-disable react/no-this-in-sfc */
  //         this.format('margin', '0px');
  //       }

  //       static tagName = 'P';

  //       format(name, value) {
  //         if (name === 'margin') {
  //           this.domNode.style.margin = value;
  //         } else {
  //           super.format(name, value);
  //         }
  //       }
  //     }
  //     ReactQuill.Quill.register(CustomBlock, true);
  //   };
  //   getQuillBlots();
  // }, []);

  // useEffect(() => {
  //   const getQuillBlots = async () => {      
  //     const ReactQuill = (await import('react-quill')).default;
  //     const Inline = ReactQuill.Quill.import('blots/inline');
  //     class AskChatGPTBlot extends Inline {
        
  //       static blotName = 'ask-chatgpt';
  //       static tagName = 'span';
  //       static className = 'ask-chatgpt';
      
  //       static create(value) {
  //         const node = super.create(value);
  //         node.setAttribute('data-text', value);
  //         return node;
  //       }
      
  //       static formats(node) {
  //         return node.getAttribute('data-text');
  //       }
  //     }
  //     ButtonBlot.blotName = 'button';
  //     ButtonBlot.tagName = 'button';
  //     ReactQuill.Quill.register(AskChatGPTBlot);   
  //   };
  //   getQuillBlots();
  // }, []);

  useEffect(() => {
    if(!currentUserUUID){
      return;
    }
      const onChildAdd = Firebase.database()
      .ref(`/onroadai/mostpopularsearches/`)
      .limitToLast(27)  
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];
            if (element && element.prompt) {
              result.push({prompt: element.prompt})
            }
          }
          setMostPopularSearches(result)
        }
      });
     
    return () => Firebase.database().ref(`/onroadai/mostpopularsearches/`).off('value', onChildAdd);
  }, [currentUserUUID]);


  // useEffect(() => {
  //   if (quillRef.current) {
  //     if (editorLastLine && editorLastLine.length > 0) {
  //       const currentEditor = quillRef.current.getEditor();
  //       currentEditor.insertText(500, "Text added to editor!");
  
  //         // Set the cursor to the end of the editor
  //         currentEditor.setSelection(currentEditor.getLength(), currentEditor.getLength());
  //     }
     
  //   }
  // }, [editorHtml]);

  const askChatGPT = async () => {
    setAskingChatGPT(true)
    setRecentSearch(null)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "FRwt0F9CAxucS1Shwl1+vUnpQ==");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "prompt": formValue
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const result = await fetch("https://us-central1-onroad3-e593b.cloudfunctions.net/callChatGPT", requestOptions).then(response => response.json()).catch(error => error);
    setAskingChatGPT(false)

    setRecentSearch({
      "prompt": formValue,
      "iframeUrl": `https://share.listnr.tech/embed/voiceover/${result.audioKey}/?minimal=true`,
      "time": moment().format('DD MMMM YYYY h:mm A'),
      "text": result.text
    })

    Firebase.database()
      .ref(`/onroadai/users/${currentUserUUID}/searches`).push({
        "prompt": formValue,
        "responsefield": `https://share.listnr.tech/embed/voiceover/${result.audioKey}/?minimal=true`,
        "time": moment().format('DD MMMM YYYY h:mm A'),
        "speechresult": result
      })
  }


  const mostPopularAskChatGPT = async (prompt) => {
    if (!prompt) {
      return;
    }
    setAskingChatGPT(true)
    setRecentSearch(null)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "FRwt0F9CAxucS1Shwl1+vUnpQ==");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "prompt": prompt
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const result = await fetch("https://us-central1-onroad3-e593b.cloudfunctions.net/callChatGPT", requestOptions).then(response => response.json()).catch(error => error);
    setAskingChatGPT(false)
    setRecentSearch({
      "prompt": formValue,
      "iframeUrl": `https://share.listnr.tech/embed/voiceover/${result.audioKey}/?minimal=true`,
      "time": moment().format('DD MMMM YYYY h:mm A'),
      "text": result.text
    })
    Firebase.database()
      .ref(`/onroadai/users/${currentUserUUID}/searches`).push({
        "prompt": prompt,
        "responsefield": `https://share.listnr.tech/embed/voiceover/${result.audioKey}/?minimal=true`,
        "time": moment().format('DD MMMM YYYY h:mm A'),
        "speechresult": result
      })
  }
  const clearAllSearches = () => {
    Firebase.database().ref(`/onroadai/users/${currentUserUUID}/searches`).set(null)
    setCurrentUserSearches(null)
  }

  const emailMyContent = async () => {
    setEmailModal(true)
  }
  const sendEmail = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "FRwt0F9CAxucS1Shwl1+vUnpQ==");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "email": contentEmailToSend,
      "user": currentUserUUID
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
                  
    await fetch("https://us-central1-onroad3-e593b.cloudfunctions.net/sendBlueEmail", requestOptions).then(response => response.text()).catch(error => error);
    //  setAskingChatGPT(false)
    

    // var myHeaders = new Headers();
    // myHeaders.append("Authorization", "FRwt0F9CAxucS1Shwl1+vUnpQ==");
    // myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify({
    //   "email": contentEmailToSend,
    //   "userid": currentUserUUID
    // });

    // var requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: 'follow'
    // };

    // fetch("https://us-central1-onroad3-e593b.cloudfunctions.net/sendWithSendGrid", requestOptions)
    // .then(response => response.text())
    // .then(result => console.log("---email response---", result))
    // .catch(error => console.log('--email errror===', error));
      
    
    //  setContentEmailToSend(null)
//     var myHeaders = new Headers();
// myHeaders.append("Authorization", "FRwt0F9CAxucS1Shwl1+vUnpQ==");

// myHeaders.append("Content-Type", "application/json");

// var raw = JSON.stringify({
//   "email": "itzhari.g@gmail.com",
//   "user": "-NOvumqrHQe6-ZZNaUXJ"
// });

// var requestOptions = {
//   method: 'POST',
//   mode: 'no-cors',
//   headers: myHeaders,
//   body: raw,
//   redirect: 'follow'
// };

// var result = await fetch("https://us-central1-onroad3-e593b.cloudfunctions.net/sendBlueEmail", requestOptions)
//   .then(response => response.text())
//   .then(result => console.log("---result===", result))
//   .catch(error => console.log('---error===', error));
    
    
// const apiKey = "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"; // Replace with your actual API key
// const url = "https://api.sendgrid.com/v3/mail/send";

// const headers = {
// Authorization: `Bearer ${apiKey}`,
// "Content-Type": "application/json",
// };

// const data = {
// personalizations: [
//     {
//     to: [
//         {
//         email: "itzhari.g@gmail.com",
//         },
//     ],
//     },
// ],
// from: {
//     email: "dan@onroad.app",
// },
// subject: "Example subject",
// content: [
//     {
//     type: "text/html",
//     value: "<html><body><h1>Example HTML email body</h1><p>This is an example HTML email body.</p></body></html>",
//     },
// ],
// };

// fetch(url, {
// method: "POST",
// headers: headers,
// body: JSON.stringify(data),
// })
// .then((response) => {
//     if (response.ok) {
//     console.log("Email sent successfully.");
//     } else {
//     console.error("Error sending email:", response.statusText);
//     }
// })
// .catch((error) => {
//     console.error("Error sending email:", error);
// });
  }
  const openInTeslaBrowserCard = () => {
    return (
      <>
        <MDAlert color="success">
        <MDBox >
        <Grid container>
          <Grid item xs={12}>
          <MDBox  display="flex" alignItems="center">
            {/* <MDAvatar alt="Avatar" size='xl' variant="rounded" src={}>
            </MDAvatar> */}
            
              <MDBox ml={2} mt={0.5} lineHeight={1.4} style={{width: "100%"}} >
              <MDTypography variant="h6" color="white" style={{textAlign: "center"}}>
                Access onroad.ai on your Tesla car browser
              </MDTypography>
              <MDTypography variant="body2" color="white"  style={{textAlign: "center"}}>
                      Fan-made ChatGPT integration for Tesla vehicles!                      
                    </MDTypography>
                    <MDTypography variant="body2" color="white"  style={{textAlign: "center"}}>
                      Ask ChatGPT anything and listen to its response.
                    </MDTypography>
                    <MDTypography variant="body2" color="white"  style={{textAlign: "center"}}>
                    Create amazing content on the go!
                    </MDTypography>
                    <MDTypography variant="body2" color="white" style={{textAlign: "center", }}> 
             
                    <MDButton  color="white" iconOnly onClick={() => {setWatchVideo(true)}}>
                    <Icon>play_arrow</Icon>
                    </MDButton>
                    <br />
                    <p style={{fontSize: "10px", fontWeight: "600"}}>Watch Demo</p>    

                     </MDTypography>

              </MDBox>
            </MDBox>            
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
              <MDBox component="img" src={chatgptimage} alt="chatgpt" width="100%" height="100vh" style={{minWidth: window.innerWidth - 100}} />
              </Grid>
          </Grid>
          
         </MDBox>
      
        </MDAlert>
        <br />
      </>

    )

  }
  const instructionsCard = () => {
    return (<></>)
  }
  
  const brandingCard = () => {
    return (
      <>
      <Dialog open={askingChatGPT}  aria-labelledby="form-dialog-title" fullWidth>
        <DialogContent>
        <CircularProgress /> Asking ChatGPT for answers
        
        </DialogContent>
        </Dialog>
      
        <MDAlert color="success" dismissible>
          <Icon fontSize="large">notifications</Icon>&nbsp;
          <MDBox >
          <Grid container>
            <Grid item xs={12}>
            <MDBox  display="flex" alignItems="center">
              {/* <MDAvatar alt="Avatar" size='xl' variant="rounded" src={}>
              </MDAvatar> */}
              
                <MDBox ml={2} mt={0.5} lineHeight={1.4} >
                  <MDTypography variant="body2" color="white"> 
                        <b>Input: </b>When the keyboard appears, use the microphone button to input text
                        <br />
                        <b>Audio while driving: </b>If audio is not playing while driving, open the FM app and start playing audio. Afterward, switch the audio source to Bluetooth or another option and close the FM app. Open the web browser and press the play button to start playback.
                </MDTypography>
                </MDBox>
              </MDBox>            
              </Grid>
            </Grid>
            
          </MDBox>&nbsp;&nbsp;
          <MDTypography variant="body2" color="white"> 
             
              <MDButton  color="white" iconOnly style={{minWidth: "60px"}} onClick={() => {setWatchVideo(true)}}>
              <Icon>play_arrow</Icon>
              </MDButton>
              <br />
              <p style={{fontSize: "10px", fontWeight: "600"}}>Watch Demo</p>    

                </MDTypography>
         
          
        </MDAlert>
        <br />
      </>

    )
  }
  const documentModalClose = () => {
    setDocumentOpen(false)
  }

  const handleSaveDocument = async (e) => {
    e.preventDefault();
    if (quillRef && quillRef.current) {
      const htmlFile = new Blob([quillRef.current.value], { type: 'text/html' });
      const fileName = quillRef.current.value.slice(0, 50).replaceAll(/<\/?[^>]+(>|$)/gi, "");

      const storageRef = Firebase.storage().ref(`awesomecontent/${currentUserUUID}/${fileName}.html`)
      await storageRef.put(htmlFile)
      const publicurl = await storageRef.getDownloadURL()

      if (publicurl) {
        Firebase.database()
        .ref(`/onroadai/users/${currentUserUUID}/content`).push({
          "filename": fileName,
          "filepath": publicurl,
          "publishedtime": moment().format('DD MMMM YYYY h:mm A')
        })
      }

      setDocumentOpen(false)
      openSuccessSB()
    }
    
  };
  
 
  const getLastTextNode = (htmlString) => {
    // const div = document.createElement("div");
    // div.appendChild(htmlElement.cloneNode(true));
  
    // // Get all the text nodes in reverse order.
    // const textNodes = Array.from(div.childNodes).reverse().filter(node => node.nodeType === Node.TEXT_NODE);
    // console.log("====nodes===", textNodes)
    // // Find the last non-empty text node and return its contents.
    // const lastTextNode = textNodes.find(node => node.textContent.trim() !== '');
    // return lastTextNode ? lastTextNode.textContent.trim() : '';

    const div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    console.log("==div===", Array.from(div.childNodes))
    // Get all the text nodes in reverse order.
    const textNodes = Array.from(div.childNodes).reverse().filter(node => node.nodeType === Node.TEXT_NODE);
    console.log("==textNodes===", textNodes)
    // Find the last non-empty text node and return its contents.
    const lastTextNode = textNodes.find(node => node.textContent.trim() !== '');
    return lastTextNode ? lastTextNode.textContent.trim() : '';
  }
  function getLastNode(htmlString) {
    const div = document.createElement("div");
    div.innerHTML = htmlString.trim();
  
    const lastNode = div.lastChild;
    const result = lastNode.nodeType === Node.TEXT_NODE ? lastNode.previousSibling : lastNode;
    return result.textContent
  }

  const handleChange = async (content, delta, source, editor) => {
    
    //   const newHtml = html.replace(lastLine, completion);
      //   setEditorHtml(newHtml);
    
    // const result = content.replace(getLastNode(content), `Asking ChatGPT For: ${getLastNode(content)}`).concat("<p><br></p>");
    setEditorHtml(`${content} wassup`);
    console.log("===delta=====",content, getLastNode(content), delta, editor.getContents())
    // let prompt = content.getText()
    // // if (prompt) {
    // //   prompt = prompt.replace(/[^a-zA-Z0-9]/g, '');
    // // }
    // if (prompt && prompt != previousPrompt) {
      
    //   console.log("===calling chatgpt===", prompt, getLastQuestion(prompt))
    //   setPreviousPrompt(getLastQuestion(prompt))
    // }
      // setEditorHtml(html);
      // const lines = html.split('\n');
      // const lastLine = lines[lines.length - 1].trim();
      // if (lastLine !== '') {
      //   const response = await fetch('https://api.openai.com/v1/engines/davinci-codex/completions', {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'Authorization': 'Bearer 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'
      //     },
      //     body: JSON.stringify({
      //       prompt: "founders of google",
      //       max_tokens: 60,
      //       n: 1,
      //       stop: ['\n']
      //     })
      //   });
  
      //   const data = await response.json();
      //   const completion = data.choices[0].text.trim();
      //   console.log("=====completion==", data)
      //   const newHtml = html.replace(lastLine, completion);
      //   setEditorHtml(newHtml);
      // }

    };
  const createDocumentCard = () => {
   
    return (
      <>
      <Dialog open={documentOpen} onClose={documentModalClose} aria-labelledby="form-dialog-title" fullScreen>
        <DialogTitle id="form-dialog-title" style={{paddingBottom: "0px", fontSize: "15px", backgroundColor: "#eeeeee"}}>
          
          <Grid container direction="row" justify="space-between">
            <div style={{color: "gray", fontSize: "10px"}}>
            {`Speak your prompt into the microphone. Keyboard input may not work. Editor will be read only when waiting for ChatGPT response`} &nbsp;&nbsp;  {``}&nbsp;&nbsp; 
              {/* <a href={getSharableLink(teamMemberDetails[1])} target="_blank" style={{fontSize: "15px"}}>
                {getSharableLink(teamMemberDetails[1])} <LaunchIcon style={{paddingLeft: "5px", paddingTop: "5px", fontSize: "1rem"}} />
                </a> */}
              </div>
            
            <IconButton aria-label="close" onClick={handleSaveDocument} style={{ position: "absolute", right: "60px", top: "0px" }}>
              <CheckIcon />
            </IconButton>
            <IconButton aria-label="close" onClick={documentModalClose} style={{ position:"absolute", right:"8px", top: "0px" }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
      
			  <DialogContent style={{backgroundColor: "#eeeeee"}}>
			  {/* <h4>{address}</h4> */}
			  {/* <img src={`https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/teamtrack.jpg?alt=media&token=7e2f91c8-12d4-4178-be1a-2c29a76cbb78`} title="" style={{width: "100%"}}/> */}
        

        <ReactQuill
          ref={quillRef}
          value={editorHtml}
          onChange={async (content, delta, source, editor) => {
           
          if (source === 'user') {
            const t = editor.getText()
            const lines = t.split('\n');
            let lastLine;

            for (let index = lines.length-1; index > -1; index--) {
              const element = lines[index];
              if (element) {
                lastLine = element
                break
              }
            }
            // setEditorLastLine(lastLine)

            let doWeNeedToCallChatGPT = false 
            let lastDeltaLine;
            if (delta && delta.ops && delta.ops.length > 0) {
              for (let index = 0; index < delta.ops.length; index++) {
                const element = delta.ops[index];
                if (element && element.insert) {
                  lastDeltaLine = element.insert
                }
              }
            }

            // editor.insertText(position+5, `Asking Chatgpt for ${lastLine}`)
            if (quillRef && quillRef.current && lastDeltaLine == lastLine) {

              const currentEditor = quillRef.current.getEditor();
              let index = editor.getLength();
              if (lastLine && lastLine.split(" ").length > 3) {
                // setAskingChatGPT(true)
                var myHeaders = new Headers();
                myHeaders.append("Authorization", "FRwt0F9CAxucS1Shwl1+vUnpQ==");
                myHeaders.append("Content-Type", "application/json");
            
                var raw = JSON.stringify({
                  "prompt": lastLine
                });
            
                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: raw,
                  redirect: 'follow'
                };
                currentEditor.enable(false);
                              
                const result = await fetch("https://us-central1-onroad3-e593b.cloudfunctions.net/ChatGPTTextResponse", requestOptions).then(response => response.text()).catch(error => error);
                //  setAskingChatGPT(false)
                
                
                currentEditor.insertText(index, `${'\n'} ${result}${'\n\n\n'}`);
                
                currentEditor.setSelection(editor.getLength(), editor.getLength());
                currentEditor.enable(true);
              }
            }
           
            // const pEdit = quillRef.getEditor();
            // console.log("==quillRef===", range)
            // handleChange
          } 

         
        }}
        // theme="snow"
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [ { list: 'ordered' },{ list: 'bullet' },{ indent: '-1' },{ indent: '+1' }],
            ['link', 'image'],
            ['clean'],
          ]
        }}
        // formats={formats}
        style={{height: "100vh"}}
        
        />

			</DialogContent>
			{/* <DialogActions>
			  <Button onClick={adderessTrackClose} color="primary">
				close
			  </Button>
			</DialogActions> */}
		  </Dialog>
        <Grid container alignItems="center">
          <Grid item xs={12} md={9}>
            <MDBox mb={1}>
              <MDTypography variant="h5">Create amazing content on the go!</MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="body2" color="text">
              Effortlessly input prompts into your document using your voice and get instant responses from ChatGPT.
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3} sx={{ textAlign: "right" }}>
            <MDButton size="small" variant="gradient" color="light" onClick={() => {
              setDocumentOpen(true)
            }}>
              <Icon>add</Icon>&nbsp; Create New
            </MDButton>
          </Grid>
        </Grid>
      </>
    )
  }
  const promptCard = () => {
    return (<>
      <Card sx={{ height: "100%" }}  >
        <div style={{padding: "10px"}}>
          <MDInput label="Ask me anything..." multiline rows={5} size="xlarge" sx={{ width: "100%" }} value={formValue} onChange={handeSetFormValue} style={{fontSize: "20px"}} />
        </div>
        <MDBox alignItems="center" sx={{textAlign: "center"}} pb={2}>
         <MDButton variant="gradient" color="info" disabled={formValue ? false : true} onClick={() => {
                          askChatGPT()
                          setFormValue("");
                        }}>
            <Icon>send_outlined</Icon>&nbsp; Ask ChatGPT
          </MDButton>
        </MDBox>
        
      </Card>
      <br />
    
    </>)

  }
  const mostRecentSearchResult = () => {
    return (
      <> <Card sx={{ height: "100%" }}>
       <MDBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
          <MDTypography>Result</MDTypography>
          <MDButton size="small" variant="gradient" color="text" container  onClick={() => {
                          mostPopularAskChatGPT(recentSearch.prompt)
                        }}>Ask Again </MDButton>
        </MDBox>
        
        <Grid container>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} p={1}>
            <MDBox>
            {recentSearch && (<>
              <MDBox >
                <DefaultItem
                  color="dark"
                  icon="notifications_active"
                  title={recentSearch.prompt}
                  description={recentSearch.time}
                  iframeUrl={recentSearch.iframeUrl}
                />
              </MDBox>
            </>)}
            </MDBox>
          </Grid>

          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
          {recentSearch && (<>
              <MDBox>
                <MDTypography>{recentSearch.text}</MDTypography>
              </MDBox>
            </>)}
          </Grid>
        </Grid>

      
      </Card>
        <br />
      </>
     
    )
  }
  const allResultsCard = () => {
    return (
      <> <Card sx={{ height: "100%" }}>
       <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDTypography>Your Recent Searches</MDTypography>
          
          {/* <MDButton onClick={() => {
            console.log("======")
          }}
            
             style={{color: "blue", float: "right", fontSize: "10px", marginRight: "-150px", textTransform: "capitalize"}}
        
                >
                  Email these
          </MDButton> */}
          <MDButton size="small" color="light" container disabled={!currentUserSearches} onClick={() => {
                          clearAllSearches()
                        }}>Clear All </MDButton>
       </MDBox>
        <MDBox p={2}>
          {currentUserSearches && currentUserSearches.length > 0 ? currentUserSearches.map((search) => (<>
            <MDBox mt={2.5}>
              <DefaultItem
                color="dark"
                icon="notifications_active"
                title={search.prompt}
                description={search.time}
                iframeUrl={search.iframeUrl}
              />
            </MDBox>
          </>)) : (<><MDTypography variant="h6" style={{textAlign: "center", color: "gray", height: "40px"}}>No Searches Yet</MDTypography></>)}
        {/* <DefaultItem
          color="dark"
          icon="savings"
          title={formValue}
          description={moment().format('DD MMMM YYYY h:mm A')}
          iframeUrl={iframeUrl}
        /> */}
          
       

        </MDBox>
      </Card>
        <br />
      </>
     
    )
  }

  const validateEmail = (email) => {
    // regex pattern to match email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // return true if email matches regex pattern, false otherwise
    return emailRegex.test(email);
  }

  const yourContentCard = () => {
    return (
      <>
      <Dialog open={emailModal}  aria-labelledby="form-dialog-title" fullWidth>
          <DialogContent>    
          <MDBox mb={4}>
              <MDInput type="email" label="Email" variant="standard" fullWidth onChange={(e) => {
                if (validateEmail(e.target.value)) {
                  setInvalidEmail(false)
                  setContentEmailToSend(e.target.value)
                } else {
                  setInvalidEmail(true)
                }
                
              }} />
               <div style={{color: "gray", fontSize: "10px"}}>
            {`Please enter a valid email`}
              {/* <a href={getSharableLink(teamMemberDetails[1])} target="_blank" style={{fontSize: "15px"}}>
                {getSharableLink(teamMemberDetails[1])} <LaunchIcon style={{paddingLeft: "5px", paddingTop: "5px", fontSize: "1rem"}} />
                </a> */}
              </div>
            </MDBox>  
          </DialogContent>
          	<DialogActions>
            <MDButton onClick={() => { setEmailModal(false); setInvalidEmail(false) }} color="primary" size="small">
				close
            </MDButton>
            <MDButton onClick={() => {
              if (!invalidEmail) {
                sendEmail();
                setEmailModal(false)
                setInvalidEmail(false)
              }
              
            }} color="success" size="small" disabled={!contentEmailToSend}>
				Send
			  </MDButton>
			</DialogActions>
      </Dialog>
        <MDSnackbar
          color="success"
          icon="check"
          title="File saved successfully!"
          content="To access it, please scroll down to the bottom of the screen."
          dateTime="1 mins ago"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite
        />
        
        <Card sx={{ height: "100%" }}>
       <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDTypography>Your Content</MDTypography>
          
          {/* <MDButton onClick={() => {
            console.log("======")
          }}
            
             style={{color: "blue", float: "right", fontSize: "10px", marginRight: "-150px", textTransform: "capitalize"}}
        
                >
                  Email these
          </MDButton> */}
          <MDButton size="small" color="light" container disabled={!myContent || (myContent && myContent.length == 0)} onClick={() => {
                          emailMyContent()
                        }}>Email Me </MDButton>
       </MDBox>
        <MDBox p={2}>
          {myContent && myContent.length > 0 ? myContent.map((doc) => (<>
            <MDBox ml={2} mt={0.5} lineHeight={1.4}>
              <MDTypography display="block" variant="h6" fontWeight="regular" color="text" fontSize="large">
                {doc.filename}
              </MDTypography>
              <MDTypography
                component="a"
                href={doc.filepath}
                target="_blank"
                rel="noreferrer"
                // variant="h5"
                // textTransform="capitalize"
                style={{
                  fontSize: "10px",color: "blue"
                }}
              >
                {doc.filepath}
              </MDTypography>
              <br />
              <MDTypography variant="button" fontWeight="regular" color="text">
                {/* {doc.filepath} */}
                {doc.publishedtime}
              </MDTypography>
            </MDBox>
            <Divider />
          </>)) : (<><MDTypography variant="h6" style={{textAlign: "center", color: "gray", height: "40px"}}>No Content Created Yet</MDTypography></>)}
        {/* <DefaultItem
          color="dark"
          icon="savings"
          title={formValue}
          description={moment().format('DD MMMM YYYY h:mm A')}
          iframeUrl={iframeUrl}
        /> */}
          
       

        </MDBox>
      </Card>
        <br />
      </>
     
    )
  }
  const mostPopularPrompts = () => {
    return (<>
      <Card id="2fa" sx={{ overflow: "visible" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDTypography >Most popular prompts</MDTypography>
          <MDButton size="small" color="light" alignItems="center"  onClick={() => {
                          mostPopularAskChatGPT("Tell me anything random")
                        }}>Ask Anything Random </MDButton>
      </MDBox>
        <MDBox p={3}>
          {mostPopularSearches && mostPopularSearches.length > 0  && mostPopularSearches.map((search) => (
            <>
              <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <MDTypography variant="h6" fontWeight="regular" color="text" fontSize="large">
                {search.prompt}
              </MDTypography>
              <MDBox
                display="flex"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
              <MDButton size="small" color="light" alignItems="center" onClick={() => {
                mostPopularAskChatGPT(search.prompt)
              }}>Ask</MDButton>
               
              </MDBox>
            </MDBox>
            <Divider />
            </>
            
          ))}
          
        </MDBox>
    </Card>
    </>)
  }

  return (
    <DashboardLayout>
         <Dialog open={watchVideo}  onClose={()=> {setWatchVideo(false)}} aria-labelledby="form-dialog-title">
          <DialogContent>
          <iframe width="560" height="315" src={isTeslaCarBrowser ? "https://firebasestorage.googleapis.com/v0/b/onroadai/o/OnroadAI.mp4?alt=media&token=9e179386-ef06-4c7c-b721-bfb70f5b8831" : "https://www.youtube.com/embed/miijx-inT54"} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>			    </DialogContent>
		    </Dialog>
      {!isTeslaCarBrowser ? (<>
        {openInTeslaBrowserCard()}
      </>) : (<>
        {instructionsCard()}
        {brandingCard()}
        {createDocumentCard()}
        {promptCard()}
        {recentSearch && mostRecentSearchResult()}  

         <Grid container>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={5.8} xl={5.8}>
            {allResultsCard()}  
          </Grid>
          <Grid item xs={0.4} sm={0.4} md={0.4} lg={0.4} xl={0.4} >
            
          </Grid>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={5.8} xl={5.8}>
            {mostPopularPrompts()}
          </Grid>
          </Grid>
          <br />
          <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {yourContentCard()}
              </Grid>
        </Grid>
        <div style={{color: "gray", fontSize: "12px"}}>
            {`If you need any features, please feel free to email us at dan@onroad.app. You can also stay up to date with the latest news and updates by following our Twitter account`} <a href="https://twitter.com/onroadai">@OnroadAI</a> &nbsp;&nbsp;  {``}&nbsp;&nbsp; 
        </div>
          
      </>)}
      
    </DashboardLayout>
  );
return (<></>);
}

export default Home;
