
import CssBaseline from "@mui/material/CssBaseline";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// import Deals from "layouts/dashboards/deals";
import Home from "layouts/dashboards/home";
// import Myorders from "layouts/dashboards/myorders";
import { useEffect } from "react";
// react-router components
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
// import {loadStripe} from '@stripe/stripe-js';
// import {
//   CardElement,
//   Elements,
//   useStripe,
//   useElements,
// } from '@stripe/react-stripe-js';

// const stripePromise = loadStripe('pk_test_51K9af5BfGfOYVrvwzumqhvEMn4mkG8P45l3B2u2ertsjdJxGfPOwfX7RxTjN1RaYLP6COVMAgC3xFxdPs4ulsWd800qUHy0sL1');

export default function App() {

  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);


  return (
      <ThemeProvider theme={theme} stylep={{width: "100%"}}>
        <CssBaseline />
          {/* <Configurator /> */}
        <Routes>
          {/* <Route exact path="/myorders" element={<Myorders />} key="myorders" />
          <Route exact path="/deals" element={<Deals />} key="deals" /> */}
          <Route  path="*" element={<Home />} key="home" />
        </Routes>
      </ThemeProvider>  
  );
}
